// import React from 'react'
// import { useEffect } from "react";
// import { dataLayer } from "react";
// const Analytics = () => {
//   useEffect(() => {
//     // Create the script tag
//     const script = document.createElement("script");
//     script.src = "https://www.googletagmanager.com/gtag/js?id=G-XVLR1PF86N";
//     script.async = true;

//     // Append the script tag to the document
//     document.head.appendChild(script);

//     // Initialize Google Analytics (gtag)
//     script.onload = () => {
//       window.dataLayer = window.dataLayer || [];
//       function gtag() {
//         dataLayer.push(arguments);
//       }
//       gtag("js", new Date());
//       gtag("config", "G-XVLR1PF86N");
//     };

//     // Cleanup the script when the component is unmounted
//     return () => {
//       document.head.removeChild(script);
//     };
//   }, []); // Empty dependency array ensures this effect runs only once when the component mounts

//   return null; // This component doesn't render anything to the DOM
// };

// export default Analytics;

import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-XVLR1PF86N";

export const initializeAnalytics = () => {
  ReactGA.initialize(GA_TRACKING_ID); // Replace with your Measurement ID
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
