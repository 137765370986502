import React, { useEffect, useState } from "react";
import './ItTeamAugmentation.css'
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSetServices from "../../comp/TextSetServices/TextSetServices";
import BottomCta from "../../comp/BottomCta/BottomCta";
import TextSetMini from "../../comp/TextSetMini/TextSetMini";
import TextSetMini2 from "../../comp/TextSetMini2/TextSetMini2";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import { Helmet } from "react-helmet";
import {
  ItTeamAugmentationLanding,
  WebDeveloper,
  FrontEndAndBackendDeveloper,
  FrontendBackend_Developers,
  ITArgu_UiuxDesigners,
  ITArgu_Project_Managers,
  ITArgu_Business_Analysts,
  ITArgu_DevOps_Engineers,
  ITArgu_Cloud_Developers,
  ITArgu_QA_Test,
  ITArgu_Web_Developers,
  ITArgu_Mobile_Developers,
  ITArgu_Product_Designers,
  UiuxDesigners,
  BydgetManagement,
  BusinessManHolding,
  FlatTireUnder,
  CLoudServer,
  QaTesterAutomation,
  Mobile,
  ProductDesigner
} from '../../assests/images';


export default function ItTeamAugmentation({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForServices = [
    { 'icon': FrontendBackend_Developers, 'heading': 'Frontend and Backend Developers' },
    { 'icon': ITArgu_UiuxDesigners, 'heading': 'UI/UX Designers' },
    { 'icon': ITArgu_Project_Managers, 'heading': 'Project Managers' },
    { 'icon': ITArgu_Business_Analysts, 'heading': 'Business Analysts' },
    { 'icon': ITArgu_DevOps_Engineers, 'heading': 'DevOps Engineers' },
    { 'icon': ITArgu_Cloud_Developers, 'heading': 'Cloud Developers' },
    { 'icon': ITArgu_QA_Test, 'heading': 'QA & Test automation engineers' },
    { 'icon': ITArgu_Web_Developers, 'heading': 'Web Developers' },
    { 'icon': ITArgu_Mobile_Developers, 'heading': 'Mobile Developers' },
    { 'icon': ITArgu_Product_Designers, 'heading': 'Product Designers' },
  ]

  const alloutsorcingData = [{
    'heading': 'Frontend and Backend Developers', 'img': FrontEndAndBackendDeveloper,
    'subheading': 'We hire experts in frontend and backend development at Gravit Infosystems to bring your ideas to life. Our experts create user-friendly interfaces with strong server-side solutions to ensure smooth users experiences while using the product. Let us build your dream project today.'
  },
  {
    'heading': 'UI/UX Designers', 'img': UiuxDesigners,
    'subheading': 'Hiring our UI/UX designers can help you retain customers by improving application designs and usability that lead to engaging designs.'

  },
  {
    'heading': 'Project Managers', 'img': BydgetManagement,
    'subheading': ' Hire our highly competent project managers to execute projects with excellent communication, leadership, team management, critical thinking, and risk management skills while continuously lowering costs and guaranteeing on-time delivery.'

  },
  {
    'heading': 'Business Analysts', 'img': BusinessManHolding,
    'subheading': 'Hire business analysts from Gravit Infosystems to make your project a success. Analytically strong business analysts research the market trend, set goals, and provide you with valuable insights for smart decisions to grow your business using data.'
  },
  {
    'heading': 'DevOps Engineers', 'img': FlatTireUnder,
    'subheading': 'Hire business analysts from Gravit Infosystems to make your project a success. Analytically strong business analysts research the market trend, set goals, and provide you with valuable insights for smart decisions to grow your business using data.'

  },
  {
    'heading': 'Cloud Developers', 'img': CLoudServer,
    'subheading': 'Engage with our cloud developers to design, develop, and oversee cloud-based services and applications. Our qualified team works with your databases to enhance flexibility, reduce costs, and deliver optimized solutions for your business needs.'


  },
  {
    'heading': 'QA & Test automation engineers', 'img': QaTesterAutomation,
    'subheading': 'Engage QA & Test automation engineers to have the best creation of software. Engage them before the website and applications are made available to find bugs and other problems that would affect their smooth running.'

  },
  {
    'heading': 'Web Developers', 'img': WebDeveloper,
    'subheading': `Hire our great web developers for the highest-quality responsive mobile websites that will have a positive influence on optimizing your online presence. Our developers provide fast, secure, and user-friendly designs as well as providing personalized solutions especially addressed to your business requirements for maximum performance and growth.'
. `
  },
  {
    'heading': 'Mobile Developers', 'img': Mobile,
    'subheading': 'Hire mobile developers that turn ideas into reality and offer customized solutions with expertise . Developers create high performance mobile applications in which they are fully optimized to work on Android as well as ios. With a focus on usability and user engagement , they deliver mobile apps that align with your business goal and enhance your digital presence.'
 
  }, {
    'heading': 'Product Designers', 'img': ProductDesigner,
    'subheading': "Hire product designers who design digital products specifically for your company's needs. Our product designers first research and produce prototypes through various means to come up with innovative yet feasible and aesthetic products."
  
  },]

  return (
    <>
      <LandingPage img={ItTeamAugmentationLanding} subheading={`Looking to hire skilled developers? Our developers have the skilled insight to execute and deliver competent and high-end results.
      `} customWidth={'70%'}
        heading={'IT team augmentation'} headingType={'text'} scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"} />
      <div className="maininnercontainer">
        <Helmet>
           <title>Services - IT Augmentation - Gravit Infosystems Pvt. Ltd.</title>
<meta name="title" content=" IT Team Augmentation  "></meta>
<meta name="description" content="gravitinfosystems offers IT team augmentation services to help you hire developers, enhancing your team's skills and boosting project success."/>
<meta name="keywords"  content="hire developers "/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>

        </Helmet>


        <div className="itteamaugmentation__innerdiv">
          <TextSetMini2 heading={'Introduction'} subheading={`
          Our team of developers has years of experience in various programming languages and frameworks, and they're well-versed in the latest technologies and tools.
           We are an IT outsourcing company that takes a collaborative approach and works diligently with our clients. We believe in transparent communication and strive to keep our clients informed throughout the development process.
          By outsourcing our IT services, you'll have access to a wealth of knowledge and expertise that can help you tackle even the most complex development projects. Our developers are dedicated to delivering high-quality code that's scalable, maintainable, and meets industry standards.`}
            img={'Software-Developer.svg'} flexDirection={'row'} width={'80%'} borderBottom />


          <CustomiseWeb
            heading={" Our IT outsourcing services."}
            subheading={'From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your IT outsourcing implementation.'}
            data={dataForServices}
          />
          {/* <TextSetServices heading={'Our IT outsourcing services'} data={dataForServices}
            subheading={'From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your IT outsourcing implementation.'} /> */}

          {/* <BottomCta paddingTop={'0rem'} paddingBottom={'3rem'} text={'Hire From us'} heading={''} borderBottom /> */}

          <div className="itteamaugmentation__alloutsourcing">
            {alloutsorcingData.map((item, index) => {
              return (
                <TextSetMini heading={item.heading} img={item.img} subheading={item.subheading} />

              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}