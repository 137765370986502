import { useEffect } from "react";
import "./WebDevPage.css";
import CardList from "../../comp/CardList/CardList";
import TextSet from "../../comp/TextSet/TextSet";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import BottomCta from "../../comp/BottomCta/BottomCta";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import WorkTechnology from "../../comp/WorkTechnology/WorkTechnology";
import { Helmet } from "react-helmet";
import {
  WebDevPageLanding,
  AnaylaticAndReporting,
  ItSecurity,
  FullStackWebDeveloper,
  WebsiteApplicationIntegration,
  ECommerceWebDevelopment,
  WebSupportAndMaintantanance,
  UnDrawDone,
  React,
  Angular,
  Salesforce,
  Html,
  Css,
  JavaScript,
  Node,
  Java,
  Php,
  Laravel,
  Codeigniter,
  Website_analytics,
  Customised_reporting,
  Heat_Mapping,
  AB_testing,
  Report_design,
  Predictive_analysis,
  Executive_Dashboards,
  Collaborate,
  FullStackDeveloperServices,
  WebItSecurity,
  AnalyticsReporting,
  ECommerceWeb,
  WebSupportMaintenance,
  CustomwebDev,
  EnterprisewebDev,
  WebsiteMaintananceDev,
  BusinessApp,
  EcommerceWebsite,
  Portfoliowebsite,
} from "../../assests/images";

export default function WebDevPage({ setActive, title }) {
  useEffect(
    function () {
      document.title = title;
      setActive("Service");
    },
    [setActive, title]
  );

  const dataForCard = [
    { img: CustomwebDev, heading: "Custom Web Developement" },
    { img: EnterprisewebDev, heading: "EnterPrise Web Developement" },
    { img: WebsiteMaintananceDev, heading: "Website Maintanance" },
    { img: BusinessApp, heading: "Any Buisness App" },
    { img: EcommerceWebsite, heading: "Ecommerce and B2B" },
    { img: Portfoliowebsite, heading: "Portfolio Website" },
  ];

  const dataforcustomisweb = [
    {
      icon: Collaborate,
      heading: "Website application integration services",
    },
    {
      icon: FullStackDeveloperServices,
      heading: "Full-stack web development services",
    },
    {
      icon: WebItSecurity,
      heading: "IT Security",
    },
    {
      icon: AnalyticsReporting,
      heading: "Analytics and reporting services",
    },

    {
      icon: ECommerceWeb,
      heading: "E-commerce web development",
    },
    {
      icon: WebSupportMaintenance,
      heading: "Web Support and Maintenance Services",
    },
  ];

  const dataForAnalytics = [
    { img: Website_analytics, heading: "Website analytics" },
    { img: Customised_reporting, heading: "Customised reporting" },
    { img: Heat_Mapping, heading: "Heat Mapping" },
    { img: AB_testing, heading: "A/B testing" },
    { img: Report_design, heading: "Report design" },
    { img: Predictive_analysis, heading: "Predictive analysis" },
    { img: Executive_Dashboards, heading: "Executive Dashboards" },
  ];

  const workImgData = [
    { img: React, text: "React" },
    { img: Angular, text: "Angular." },
    { img: Salesforce, text: "Salesforce" },
    { img: Html, text: "HTML" },
    { img: Css, text: "CSS" },
    { img: JavaScript, text: "JavaScript" },
    { img: Node, text: "Node" },
    { img: Java, text: "JAVA" },
    { img: Php, text: "PHP" },
    { img: Laravel, text: "Laravel" },
    { img: Codeigniter, text: "Codeigniter" },
  ];

  return (
    <div className="webdev__container">
      <Helmet>
        <title>
          Services - Web Developement - Gravit Infosystems Pvt. Ltd.
        </title>
        <meta name="title" content="Web Developement " />
        <meta
          name="description"
          content="Gravitinfosystems is a top website development company offering custom, responsive websites to help your business grow online."
        />
        <meta
          name="keywords"
          content=" website development company, website design and development services, web development agency,E commerce website design company  "
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <LandingPage
        img={WebDevPageLanding}
        subheading={
          "From scratch, we can develop a new web application or upgrade an existing application with proven development methodologies. Being a top website development company and a trusted web development agency, we guarantee our web applications to be reliable, scalable, and secure for use.​"
        }
        heading={"Gravit crafts swift, friendly, stylish web apps."}
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />

      <WorkTechnology paragraph={``} data={workImgData} />
      <Line />
      <div className="webdev__wholecontent">
        <div className="cardlist__main__container">
          <CardList dataForCard={dataForCard} />
        </div>
        <Line />
        <TextSet
          align={"left"}
          row={"left"}
          img={UnDrawDone}
          heading={"Set the bar for website quality in the digital era. "}
          subheading={
            <>
              <br />
              At Gravit, we provide website development services. Our web
              applications are fast, user-friendly, and aesthetically pleasing.
              We are one of the top companies for website development, so you're
              well aware that our web application will be modern, efficient, and
              compatible with all devices and platforms as per the latest trends
              and industry standards.
              <br />
              <br />
              We can use our experience and development methodologies to develop
              new web applications from scratch or upgrade existing ones while
              ensuring reliable, scalable, and secure web applications meeting
              your needs.​
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"80%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
          rowReverse
        />
        <Line />
        <CustomiseWeb
          heading={
            " CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."
          }
          data={dataforcustomisweb}
        />
        <Line />
        <TextSet4
          heading={"Website application integration services"}
          trim={200}
          mainImg={WebsiteApplicationIntegration}
          subheading={
            <>
              <br />
              Gravit Infosystems provides you with full website application
              integration services that definitely fit your requirements. It is
              a website development company that specializes in creating
              seamless and efficient integration to connect your applications
              for the optimal overall result. Our website development services
              are here to ensure that your website works beautifully with other
              websites, which, in turn, will help maximize your business
              efficiency while bringing joy to your customers at the same time.
              Whether you need database integration, API integration, or
              third-party tool integration, our team is here to deliver
              dependable and secure solutions to support your objectives. Let us
              save you hours, ensuring that your work is much more productive
              with our expert integration services.
              <br />
              <br />
            </>
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        <TextSet4
          heading={"Analytics and reporting services"}
          data={dataForAnalytics}
          mainImg={AnaylaticAndReporting}
          align={"left"}
          row={"right"}
          subheading={`
             We provide a full analytics and reporting service wherein the data can be interpreted, and valuable insights about business operations are provided to our clients. Using tools and technologies, we get into analyzing your data reporting through graphs and condensation. Trends may get identified, performance may be tracked, and so intelligent decisions can be made accordingly on the data gathered. Whether it is a custom dashboard, an interactive visualization, or a detailed report we will be happy to assist you.​

​
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          row={"left"}
          img={WebSupportAndMaintantanance}
          heading={"Web Support and Maintenance Services"}
          subheading={
            <>
              <br />
              A good website requires proper periodic upkeep to ensure that it
              runs smoothly and keeps up with the current standards. The team at
              our website development company guarantees the best performance
              for your company's website. We do all we can to make sure all
              links are functioning, that content is updated regularly, problems
              solved promptly, important files backed up, and speed up your
              website.​
              <br />
              <br />
              We provide fully comprehensive support and maintenance on your
              developed website to ensure that your website is always updated
              and kept in good working order. Therefore, we assist you in
              regular updating to identify root causes of any problems and
              optimize its performance. Your business can focus on carrying on
              with its business activities with our well-designed website taken
              care of.​
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"50%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
          rowReverse
        />
        <Line />
        <TextSet4
          heading={"Full-stack web development services"}
          mainImg={FullStackWebDeveloper}
          trim={200}
          subheading={
            <>
              At Gravit Infosystems, being a full-stack web development company,
              we provide comprehensive website development services including
              front-end and back-end services on your site. Do you want a custom
              e-commerce platform for your business or just a simple blog? Our
              professional team will create responsive, user-friendly designs.
              We offer ongoing support and maintenance so that your website runs
              smoothly. Let's make your ideas come true!
              <br />
              <br />
            </>
          }
          align={"left"}
          row={"right"}
        />
        <Line />
        <TextSet
          rowReverse
          align={"left"}
          row={"left"}
          img={ECommerceWebDevelopment}
          heading={"E-commerce web development"}
          subheading={
            <>
              At Gravit Infosystems, we develop technical aspects of your
              website like well-designed payment gateways, effective designs and
              many more. As a leading e-commerce website design company, we help
              you provide your users with the experience they want, connect with
              your customers and further generate sales. With experience in web
              portal development with security, online stores, B2B and B2C
              e-commerce services, and specialized software as a service
              solution together with booking and ticketing platforms, Gravit
              Infosystems is a strong e-commerce website design company.​.
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        />
        <Line />
        <TextSet4
          heading={"IT Security"}
          mainImg={ItSecurity}
          trim={200}
          subheading={
            <>
              Our web developers ensure giving full security and development
              technologies regarding secure software development without any
              threats of hacking and malware attacks. Being the leading IT
              security service provider ourselves, we understand the risks a
              business has to face when it comes to being threatened by data
              breaches, and we work hard day in and day out to ensure our
              clients' applications are secure. From firewalls and intrusion
              detection systems to multi-factor authentication and encryption,
              we deploy a comprehensive range of security solutions to keep your
              data safe. At the back of our trusted website development company,
              we give priority in our developmental processes to security as we
              guard your valuable data from potential threats to secure your
              business. Carrying out tests for SQL injection, XSS, and remote
              code execution should also be part of a scheduled vulnerability
              scan. Penetration testing includes the running of a manual
              security test of critical applications to avoid modern threats. An
              SSL certificate is installed to create HTTPS websites. Many users
              trust them by default since their traffic is encrypted using
              SSL/TLS. We are an IT security service provider. We scan through
              your website to ensure the elimination of all malware that has
              somehow been finding its way into your system unknowingly, thus
              ensuring your online presence is safe.​
              <br />
              <br />
              <ul>
                <li>Executing tests for SQL injection.</li>
                <li>XSS.</li>
                <li>
                  Remote code execution as part of a routine vulnerability
                  assessment.
                </li>
                <li>
                  Running a manual security test for important applications as
                  part of penetration testing helps to thwart sophisticated
                  threats.
                </li>
                <li>
                  Installation of an SSL certificate creates HTTPS websites,
                  which are a more secure version of a website and are typically
                  trusted by users because their traffic is encrypted using
                  SSL/TLS.
                </li>
                <li>
                  We conduct a malware scan on your website and eliminate any
                  malicious files that might have crept into your system
                  undetected.
                </li>
              </ul>
            </>
          }
          align={"left"}
          row={"right"}
        />
        <Line />
        {/* <BottomCta text={"Contact us"} /> */}
      </div>
    </div>
  );
}
