import { useEffect } from "react";
import "./AppDevPage.css";
import LandingPage from "../../comp/LandingPage/LandingPage";
import WorkTechnology from "../../comp/WorkTechnology/WorkTechnology";
import TextSet from "../../comp/TextSet/TextSet";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import { Helmet } from "react-helmet";
import {
  AppDevLanding,
  CustomWebIcon,
  Typography,
  ColorTheory,
  WireFramePo,
  MemphisStyle,
  Ux,
  Migrating,
  Migrating2,
  Application_Modernization_App,
  Data_Migration,
  Application_Mangement,
  Application_Performance,
  Application_Maintence,
  SupportTesting,
  PermissionPerApp,
  MaintenceServices,
  UndrawMobile,
  ApplicationMoblization,
  Flutter,
  Android,
  IOS,
  React,
  Java,
  Transformation, MobaileConsulting, UIUXAPP, TestingApp, SupportServices
} from '../../assests/images';

export default function AppDevPage({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const dataForAnalytics = [
    { img: CustomWebIcon, heading: "Branding" },
    { img: Typography, heading: "Typography" },
    { img: ColorTheory, heading: "Colour Theory" },
    { img: WireFramePo, heading: "Wireframing prototyping" },
    { img: MemphisStyle, heading: "Style guiding" },
    { img: Ux, heading: "User research and personas" },
  ];

  const dataForAppTransform = [
    { img: Migrating, heading: "Platform Migration" },
    { img: Migrating2, heading: "Application Migration" },

    { img: Application_Modernization_App, heading: "Application Modernization" },
    { img: Data_Migration, heading: "Data Migration" },
    { img: Application_Mangement, heading: "Application Management" },
    { img: Application_Performance, heading: "Application Performance Tuning" },
    { img: Application_Maintence, heading: "Application Maintenance" },
  ];

  const workImgData = [
    { 'img': Flutter, 'text': 'Flutter' },
    { 'img': Android, 'text': 'Android' },
    { 'img': IOS, 'text': 'IOS' },
    { 'img': React, 'text': 'ReactNative' },
    { 'img': Java, 'text': 'JAVA' },

  ]

  const servicesData = [
    {
      icon: UIUXAPP,
      heading: 'Mobile UI/UX Design Services',
    },
    {
      icon: MobaileConsulting,
      heading: 'Mobile Consulting Services',
    },
    {
      icon: Transformation,
      heading: 'Application Transformation and Modernization Services',
    },
    {
      icon: TestingApp,
      heading: 'Software Testing and QA Services',
    },
    {
      icon: SupportServices,
      heading: 'Maintenance and Support Services',
    },
  ];

  return (
    <div className="appdevpage__container">
      <Helmet>
           <title>Services - App Developement - Gravit Infosystems Pvt. Ltd.</title>
           <meta name="title" content="app development "/>
<meta name="description" content="gravitinfosystems, a leading mobile app development company, offers expert app development services, crafting innovative and scalable mobile solutions."/>
<meta name="keywords" content="mobile app development company, app development services, best mobile app development company       "/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>

           </Helmet>
      <LandingPage
        img={AppDevLanding}
        subheading={`We are able to offer our customers comprehensive analytics and reports, which aid in understanding their data and provide valuable insights into their business activities. Being a mobile app development company, we analyze and graphically condense information in the data further by generating reports. Our app development services will ensure you identify trends, follow the performance, and make informative decisions based on the data collected. We offer you anything from customized dashboards and interactive visualizations to detailed reports.​`}
        heading={
          "Make the most of our mobile development services to deliver the best user experience."
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      <WorkTechnology
        paragraph={`  While we continue to develop, we also offer end-to-end implementation services such as user experience design, application development, and testing. Our team has over a decade of experience in these areas and continues to strive to provide cutting-edge, specialized services to assist your business's growth.`}
        data={workImgData}
      />

      <div className="appdev__maincon">
        <CustomiseWeb
          heading={
            " CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."
          }
          data={servicesData}
        />
        {/* <TextSet3 /> */}
        <Line />
        <TextSet4
          mainImg={UndrawMobile}
          heading={"Mobile UI/UX Design Services"}
          data={dataForAnalytics}
          align={"left"}
          row={"right"}
          subheading={`
            'We are able to offer our customers comprehensive analytics and reports, which aid in understanding their data and provide valuable insights into their business activities. Being a mobile app development company, we analyze and graphically condense information in the data further by generating reports. Our app development services will ensure you identify trends, follow the performance, and make informative decisions based on the data collected. We offer you anything from customized dashboards and interactive visualizations to detailed reports.​
​.
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          img={SupportTesting}
          rowReverse
          row={"left"}
          heading={"Mobile Consulting Services"}
          subheading={
            <>
              <br />
              As a leading mobile app development company, we provide our
              clients with confidential assistance, expert guidance, and
              intensive support throughout the consulting process, starting from
              strategy and planning to user experience, app development
              services, and testing services.​ We provide extensive support
              throughout the consulting process, including expert guidance and
              intensive support, to help you resolve business issues, enhance
              ROI, and expand your business.​ ​
              <br />
              <br />
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        />{" "}
        <Line />
        <TextSet4
          heading={"Application Transformation and Modernization Services"}
          mainImg={ApplicationMoblization}
          data={dataForAppTransform}
          align={"left"}
          row={"right"}
          subheading={`
             Empower your business with the latest technology with our application transformation and modernization services. The top mobile application development company transforms legacy systems by improving functionality and seamlessly integrating it into modern platforms. Our customized application development services help to improve performance, scalability, and agility and keep your business running strongly in the fast-paced digital world. Our solutions will always put you a step ahead of the competition while ensuring success.​
             `}
        />
        <Line />
        <TextSet
          align={"left"}
          img={PermissionPerApp}
          rowReverse
          row={"left"}
          heading={"Software Testing and QA Services"}
          subheading={
            <>
              <br />
              At Gravit Infosystems, we offer complete testing, including bug
              fixing, and ensure that your software is reliable and scalable
              with good functionality that works fine on any device and
              platform. Being one of the top mobile app development companies,
              we use the latest automated and manual testing, performance
              testing, and data testing tools and techniques that are always
              aligned to industry standards. With our expert app development
              services, we provide post-release bug-fixing solutions to ensure
              high-quality software for the clients.​
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        />{" "}
        <Line />
        <TextSet
          align={"left"}
          img={MaintenceServices}
          rowReverse
          row={"right"}
          heading={"Maintenance and Support Services"}
          subheading={
            <>
              <br />
              To remain a viable business solution in today's organizations,
              applications must be updated with new technologies upon their
              deployment. As one of the best mobile app development companies,
              we provide you with maintenance and support services to make sure
              your software runs seamlessly and remains up-to-date. Our quality
              app development services rectify system errors, remove redundant
              services, and ensure full debugging and updating to make the
              applications scalable and efficient. Maintenance and support are
              important in offering applications that remain reliable, secure,
              and user-friendly.​ ​
            </>
          }
          imgBgColor={"transparent"}
          height={450}
          imageContainerWidth={"30%"}
          textContainerWidth={"70%"}
          textWidth={"100%"}
          imgBorder={"1px solid var(--themeGray)"}
        />{" "}
        <Line />
      </div>
    </div>
  );
}
