import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import './Card.css'
import Line from '../Line/Line';
import { ImCancelCircle } from "react-icons/im";
import { FaLocationDot } from "react-icons/fa6";
import { MdWork } from "react-icons/md";
export default function Card({ heading, type, location, workExp }) {
    const [toogle, setToggle] = useState(false)
    const [isVerified, setIsVerified] = useState(false);
    const [form, setForm] = useState({
        name: "",
        email: "",
        mobile: "",
        qualifiation: "",
        file: "",
        experience: "",
        expectedCTC: ""
    });

    const handleVerifyRecaptcha = (token) => {
        // This function will be called after successful verification
        if (token) {
            setIsVerified(true);
        }
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (isVerified) {
            setToggle(false)
            document.body.style.overflow = 'visible'
        } else {
            // Show error or prompt user to verify the reCAPTCHA
            console.error('Please verify reCAPTCHA!');
        }

    }
    const handletoggle = () => {
        setToggle(true)
        document.body.style.overflow = 'hidden'
        document.body.style.height = '100vh'
    }
    const closeToggle = () => {
        setToggle(false)
        document.body.style.overflow = 'visible'
    }
    return (
        <>
            <div className='card__container'>
                <h3 className='card__heading heading1'>{heading}</h3>
                <div className='card__details'>
                    <span className='card__deatils_innerBox'>
                        <p className='card__Subtitle'>{type}</p>
                    </span>
                    <span className='card__deatils_innerBox'>
                        <FaLocationDot />
                        <p className='card__Subtitle'>{location}</p>
                    </span>
                    <span className='card__deatils_innerBox'>
                        <MdWork />
                        <p className='card__Subtitle'>{workExp}</p>
                    </span>
                </div>
                <div className='card__button__container'>
                    <button onClick={handletoggle} className='card__button' >Apply</button>
                </div>
            </div>
            {toogle && (
                <div className="form__background">
                    <div className="form__container">
                        <div className='form__closeButton' onClick={closeToggle}>
                            <ImCancelCircle />
                        </div>
                        <h3 className="heading1">APPLY FOR THE POSITION</h3>
                        <Line width={"100%"} />
                        <form>
                            <div className="form__inner__Box">
                                <div className="form__input__Box">
                                    <label htmlFor="Name">Full Name <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" value={form.name} name="Name"
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            if (trimmedValue !== '') {
                                                setForm({ ...form, name: e.target.value });
                                            }
                                        }} />
                                </div>
                                <div className="form__input__Box">
                                    <label htmlFor="Email">Email <span style={{ color: 'red' }}>*</span></label>
                                    <input type="email" value={form.email} name="Email"
                                        onClick={(e) => setForm({ ...form, email: e.target.value })} />
                                </div>
                            </div>
                            <div className="form__inner__Box">
                                <div className="form__input__Box">
                                    <label htmlFor="Number">Mobile <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" value={form.mobile} name="Number"
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^[0-9]*$/.test(inputValue)) {
                                                setForm({ ...form, mobaile: inputValue });
                                            }
                                        }} />
                                </div>
                                <div className="form__input__Box">
                                    <label htmlFor="qualification">Qualification <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" value={form.qualifiation} name="qualification"
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            if (trimmedValue !== '') {
                                                setForm({ ...form, qualifiation: e.target.value });
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="form__inner__Box">
                                <div className="form__input__Box">
                                    <label htmlFor="Exper">Experience <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" value={form.experience} name="Exper"
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            if (trimmedValue !== '') {
                                                setForm({ ...form, experience: e.target.value });
                                            }
                                        }} />
                                </div>
                                <div className="form__input__Box">
                                    <label htmlFor="ECTC">Expected CTC <span style={{ color: 'red' }}>*</span></label>
                                    <input type="Number" value={form.expectedCTC} name="ECTC"
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            if (trimmedValue !== '') {
                                                setForm({ ...form, expectedCTC: e.target.value });
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="form__inner__Box">
                                <div className="form__input__Box">
                                    <label htmlFor="Resume">Upload Resume <span style={{ color: 'red' }}>*</span></label>
                                    <input type="file" name="Resume" onChange={(e) => {
                                        const fileType = e.target.files[0].type;
                                        if (fileType === 'application/pdf' || fileType === 'application/msword') {
                                            // File type is PDF or DOC, update the state
                                            setForm({ ...form, file: e.target.files[0] });
                                        } else {
                                            alert('Please select a PDF or DOC file.');
                                            e.target.value = null; // Clear the input field
                                            setForm({ ...form, file: null });
                                        }
                                    }} />
                                </div>
                                <ReCAPTCHA
                                    sitekey="6LeYZ0wpAAAAAEYgLXhzix1Q2aPN6LrY4tHygysu"
                                    onChange={handleVerifyRecaptcha}
                                />

                            </div>
                            <div>
                                <button onClick={handleSubmitForm} className="form__submit__but">Submit</button>
                            </div>
                        </form>
                    </div >
                </div >

            )
            }
        </>

    )
}
