import React, { useEffect } from "react";
import "./ItIntegration.css";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import { Helmet } from "react-helmet";
import {
  ItIntegrationLanding,
  SoftwareandHardware,
  ItDataIntegration,
  NetworkSecurity,
  UndrawMobileDevelopment,
  Data_Transfer,
  online_ERPCRM,
  payment_Gateways,
  GPS_tracking,
  Boost_delivery,
  Scalable,
  Safe_dependable,
  Best_possible,
  customized_solutions,
  quicker_access,
  Monitoring_application,
  Software_hardware_integration,
  API_Integration_Icon,
  Data_Integration_Icon,
  Network_Integration_Icon
} from '../../assests/images';

export default function ItIntegration({ setActive, title }) {
  useEffect(function () {
    document.title = title;
    setActive('Service')
  }, [setActive, title])

  const servicesData = [
    {
      icon: Software_hardware_integration,
      heading: 'Software and hardware integration',
    },
    {
      icon: API_Integration_Icon,
      heading: 'API Integration',
    },
    {
      icon: Data_Integration_Icon,
      heading: 'Data Integration',
    },
    {
      icon: Network_Integration_Icon,
      heading: 'Network Integration',
    },
  ];

  const dataForApiIntegration = [
    { img: Data_Transfer, heading: "Build Secure API for Data transfer" },
    {
      img: online_ERPCRM,
      heading: "Send online store data to your ERP or CRM.",
    },
    {
      img: payment_Gateways,
      heading: "Design payment gateways with safety features",
    },
    {
      img: GPS_tracking,
      heading: "GPS tracking in real-time for delivery services",
    },
    {
      img: Boost_delivery,
      heading: "Boost delivery while lowering costs.",
    },

  ];
  const dataForNetworkIntegration = [
    { img: Safe_dependable, heading: "Safe, dependable, and accessible" },
    { img: Scalable, heading: "Scalable" },
    { img: Best_possible, heading: "Best-possible results" },
    { img: customized_solutions, heading: "customized solutions" },
    { img: quicker_access, heading: "quicker information access" },
    { img: Monitoring_application, heading: "Monitoring and managing the application" },
  ];



  return (
    <div className="itintegration__container">
      
          <Helmet>
           <title>Services - IT Augmentation - Gravit Infosystems Pvt. Ltd.</title>
           <meta name="title" content=" IT Integration  "/>
<meta name="description" content="gravitinfosystems offers top-notch IT integration services, including software & hardware integration and seamless API integration."/>
<meta name="keywords" content="IT integration services, system integration consulting services, system integration service providers   "/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>

           </Helmet>
      <LandingPage
        img={ItIntegrationLanding}
        subheading={
          "Craft seamless linkage of data through our integration services. Our IT integration services will link your entire data, applications, APIs, and devices across your IT organization to work in harmony with one another. This way, we are able to hasten efficiency, cost-cutting, and productivity. By bringing harmony to your IT processes by simplifying complexity and enabling cross-functional collaboration across your organization using our IT integration service, you shall achieve all those things. We designed all these solutions to help in the optimization of information technology investments with a goal of achieving business objectives. Experience the benefits of our IT integration services and transform the way your organization operates."
        }
        heading={"IT Integration"}
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      <CustomiseWeb
        heading={
          " CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."
        }
        data={servicesData}
      />
      <div style={{ marginTop: "2rem" }} className="maininnercontainer">
        <Line />
        <TextSet4
          heading={"Software and hardware integration"}
          align={"left"}
          row={"left"}
          mainImg={SoftwareandHardware}
          subheading={
            "Our IT  integration services in software and hardware will cover up well in smoothing out the business's seamless user experience as they help combine the databases, cloud-based applications, servers, and storage devices of your business to your software and computer hardware. This is aimed at making systems work like a single-user environment to heighten the productivity and efficiency of your company. We'll unite your technology as you avail of IT integration. Learn how our IT integration services can impact overall performance and enhance your workflows.​."
          }
        />
        <Line />
        <TextSet4
          heading={"API Integration"}
          data={dataForApiIntegration}
          align={"left"}
          row={"right"}
          mainImg={UndrawMobileDevelopment}
          subheading={
            "Gravit has years of experience in API and web services integration.  Our IT software engineers provide API integration services, making data transfer between running programs on different systems uncomplicated and seamless.  Using our IT integration services, Your applications are ensured to communicate effectively for your aggregate efficiency and performance.​"
          }
        />
        <Line />
        <TextSet4
          heading={"Data Integration"}
          align={"left"}
          row={"left"}
          mainImg={ItDataIntegration}
          subheading={
            "The large volume of data creates challenges in interpreting the data. Our data integration services offer you the flexibility to choose and include the right kind of data as per your business needs, hence leading to a better user experience and effective utilization of data at your disposal. Our data integration services include a host of operations, such as data extraction, transformation, loading, and validation, in order to render our customers with a holistic, analytical assessment of their data. Through our system integration consulting services, we ensure that you can leverage your entire data for intelligent decision-making.​"
          }
        />

        <Line />
        <TextSet4
          heading={"Network Integration"}
          data={dataForNetworkIntegration}
          align={"left"}
          row={"right"}
          mainImg={NetworkSecurity}
          subheading={
            "We integrate the various network systems, ensuring that the clients experience great networks. Network integration is defined as how the joining of computer systems, devices, and networks brings a data-driven experience enterprise-wide. Our network integration services cover all aspects of network design, configuration, and deployment. And we offer ongoing support to ensure that the network is working continually. Our IT integration services make sure that all parts work in harmony with each other for better general performance. Let's put our faith into it and hand over the connectivity and reliability that will help you climb towards success in your organization.​"
          }
        />
      </div>
    </div>
  );
}
