import React from 'react'
import './CardContainer.css'
import Card from '../Card/Card'
export default function CardContainer() {
    return (
        <div className="cardContainer__container">
            <div className="cardContainer__sub__container">
                <h3 className="heading">Current Openings</h3>
                <p className='subheading'>
                    We are searching for new talents and require a high level of creativity, honesty, and efficiency. We provide opportunities to people who are passionate, dynamic, and accountable in their work and have the ability to submit his/her given tasks on time.
                    This is the appropriate place for you if you have these skills and love to work in a fast-growing and technological environment. We would love to welcome you to our workplace.
                </p>

                <div className="cardContainer__Cards" /* style={{backgroundColor:'var(--pastelOrange)'}} */>
                    <Card
                        heading={"React.Js Developer"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"0 to 3 Years"}
                    />
                    <Card
                        heading={"Web Developer Intern"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"0 to 1 Years"}
                    />
                    <Card
                        heading={"Full Stack Developer"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"4 to 8 Years"}
                    />
                    <Card
                        heading={"Flutter Developer"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"2 to 5 Years"}
                    />
                    <Card
                        heading={"Java Developer"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"1 to 4 Years"}
                    />
                    <Card
                        heading={"React.Js Developer"}
                        type={"Full Time"}
                        location={"Gwalior"}
                        workExp={"0 to 3 Years"}
                    />
                </div>
            </div>
        </div>
    )
}
