import React, { useEffect } from "react";
import "./SalesForcePage.css";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import LandingPage from "../../comp/LandingPage/LandingPage";
import TextSetServices from "../../comp/TextSetServices/TextSetServices";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import Line from "../../comp/Line/Line";
import { Helmet } from "react-helmet";
import {
  SalesforceLanding,
  Salesforcecommunities,
  SalesforceImplementation,
  Salesforcelighting,
  SalesforceIntegration,
  SalesforceConsulting,
  Salesforce_Implementation_ICON,
  Salesforce_Consulting,
  Salesforce_Customisation,
  Salesforce_Communities_ICON,
  Salesforce_Migration,
  Data_visualisation,
  Integration_Salesforce_Icon,
  Communities_appearance,
  Establishing_personalizing,
  Objects_security,
  Sale_Support,
  Salesforce_Lightning_ICON,
  Lightning_applications,
  communities_Lightning,
  Support_migration,
  BusinessIntelligense,
  SALESFORCE_CONSULTING,
} from "../../assests/images";
export default function SalesForce({ setActive, title }) {
  useEffect(
    function () {
      document.title = title;
      setActive("Service");
    },
    [setActive, title]
  );

  const dataForServices = [
    {
      icon: Salesforce_Implementation_ICON,
      heading: "Salesforce Implementation",
    },
    { icon: Salesforce_Consulting, heading: "Salesforce Consulting" },
    { icon: Salesforce_Customisation, heading: "Salesforce Customisation" },
    { icon: Salesforce_Communities_ICON, heading: "Salesforce Communities" },
    { icon: Salesforce_Lightning_ICON, heading: "Salesforce Lightning" },
    {
      icon: Salesforce_Migration,
      heading:
        "Buisness Intelligense, Salesforce data visualisation and Migration",
    },
    {
      icon: Data_visualisation,
      heading: "Salesforce Data Migration",
    },
    { icon: Integration_Salesforce_Icon, heading: "Integration Salesforce" },
  ];

  const dataForSalesforceCommunities = [
    {
      img: Communities_appearance,
      heading: "Communities with a corporate appearance and feel",
    },
    {
      img: Establishing_personalizing,
      heading: "Establishing and personalizing community pages",
    },
    {
      img: Objects_security,
      heading: "Objects and security at the field levelData storage planning",
    },
    { img: Sale_Support, heading: "After-Sale Support" },
  ];

  const dataForSalesforceLightning = [
    {
      img: Salesforce_Lightning_ICON,
      heading: "Salesforce Lightning Migration",
    },
    {
      img: Lightning_applications,
      heading: "Creation of Lightning pages, components, and applications",
    },
    {
      img: communities_Lightning,
      heading: "Setup customer communities on Lightning",
    },
    {
      img: Support_migration,
      heading: "Support for Lightning migration and development",
    },
  ];

  return (
    <div className="salesforce__container">
      <Helmet>
        <title>Services - SalesForce - Gravit Infosystems Pvt. Ltd.</title>
        <meta name="title" content=" Salesforce" />
        <meta
          name="description"
          content=" We offer Salesforce Implementation, Consulting, Communities, and Customization to streamline your business and drive seamless customer engagement."
        />
        <meta
          name="keywords"
          content="salesforce service provider, salesforce development services, salesforce development company  "
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>

      <LandingPage
        img={SalesforceLanding}
        subheading={
          "Our SalesForce developers enhance customer experience and boost efficiency through the most frequently used cloud-based CRM system for businesses. As a trusted salesforce service provider, we have been doing work on countless Salesforce projects and have offered customized software development through professional Salesforce services. Partner with us as your salesforce development company to meet all the business objectives."
        }
        heading={
          "Accelerate the growth of your business with our salesforce services."
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />

      <CustomiseWeb
        heading={"Our Salesforce services"}
        subheading={
          "We offer specialized implementation and salesforce maintenance services for your sales cloud, service cloud, communities, and custom development services for improvement of streamlined processes, productivity gains, and increased customer satisfaction. Our team is firmly committed to being the best Salesforce  service provider you can trust in maximizing the full potential of the Force.com platform for the fullest benefit from Salesforce. We take pride in our reputation as one of the top Salesforce development companies. Our goal is to provide you with the best service that's possible for your business needs.​"
        }
        data={dataForServices}
      />
      <br />
      <br />
      <br />
      <div className="maininnercontainer">
        
        {/* <TextSetServices
          heading={"Our Salesforce services"}
          data={dataForServices}
          subheading={
            "From implementation to configuration and customization, we offer a comprehensive range of services to ensure the success of your Salesforce implementation."
          }
        /> */}
        <Line />
        <TextSet4
          heading={"Salesforce Implementation"}
          trim={200}
          mainImg={SalesforceImplementation}
          subheading={
            <>
              <p></p>
              <br />
              <p>
                {" "}
                Business intelligence reduces costs through the analysis of
                data, which improves a sense of decision-making in the business.
                The new patterns and emerging areas require improvement; we are
                a development company of Salesforce that provides a deep
                understanding of data, enabling businesses to boost sales and
                services and keep attention to warning signs for the company. We
                will provide the data in easily understandable interactive
                graphs and dashboards, which may be used for reports,
                documentation, and presentations. What your business will gain
                with our reporting and data visualization solution is having
                even more informed decision-making with the help of real-time
                monitoring and analytics through visual analytics. You are sure
                to have the right insights that you need as a reputable
                Salesforce service provider will lead you to the best outcomes
                for the growth of your company.​ ​
              </p>
            </>
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        <TextSet4
          heading={"Salesforce Consulting"}
          trim={200}
          mainImg={SALESFORCE_CONSULTING}
          subheading={
            <>
              <p>
                With Salesforce Communities, businesses can create online
                communities where they will interact and collaborate with their
                partners, clients, and other necessary business components. As a
                Salesforce service provider, we'll help shape the community to
                fit the specific company culture and brand. Our Salesforce
                development services are set up for ultimate integration and
                customization so that it can be tailored to your unique business
                needs.​
              </p>
            </>
          }
          align={"left"}
          row={"right"}
        />
        <Line />
        <TextSet4
          heading={"Salesforce Communities"}
          data={dataForSalesforceCommunities}
          align={"left"}
          row={"left"}
          mainImg={Salesforcecommunities}
          subheading={
            "With Salesforce Communities, businesses can create online communities where they will interact and collaborate with their partners, clients, and other necessary business components. As a Salesforce service provider, we'll help shape the community to fit the specific company culture and brand. Our Salesforce development services are set up for ultimate integration and customization so that it can be tailored to your unique business needs.​"
          }
        />
        <Line />
        <TextSet4
          heading={"Salesforce Customisation"}
          // data={dataForSalesforceConsulting}
          align={"left"}
          row={"right"}
          mainImg={SalesforceConsulting}
          subheading={`Our developers engage you in developing detailed, perfectly customized end-to-end Salesforce development services for your specific business needs simple changes in fields and page layouts to more complicated customizations, building a custom application and flows. As a trusted Salesforce development company, we make sure that your company's customizations will be deployed in ways to help maximize the value extracted by the platform for supporting long-term business goals. Provide you with more control over your business through email templates, workflows, approval processes, and efficient reports and dashboards, all built by leaders in the Salesforce development space.​
​`}
        />

        <Line />
        <TextSet4
          heading={"Salesforce Lightning"}
          data={dataForSalesforceLightning}
          align={"left"}
          row={"left"}
          mainImg={Salesforcelighting}
          subheading={
            "Salesforce Lightning is a modernized version of Salesforce that is quick and easy to use, enabling users to work more promptly, effectively, and productively. As a Salesforce development company, we offer support staff to assist with planning and strategy deployment for your organization. We provide community portals, custom Salesforce apps that are ready for Lightning, and customizations. As a reliable Salesforce service provider, we ensure your needs are met with the highest expertise and efficiency.​"
          }
        />
        <Line />
        <TextSet4
          heading={"Salesforce Data Migration"}
          // data={dataForSalesforceConsulting}
          align={"left"}
          row={"right"}
          mainImg={SalesforceConsulting}
          subheading={`We will provide safe, upright services to transfer your sensitive data methodically. As a Salesforce development company, our consultants will create a unique solution for your business based on their expertise and experience to produce high-producing   results. Apart from helping you make the analysis of your data migration needs, we ensure that our services align with pre-performed tasks in a sandbox environment before the deployment of data for production. Partnering with a reliable Salesforce service provider like us can ensure that your data migration is smooth and efficient.​​.`}
        />
        <Line />
        <TextSet4
          heading={"Buisness Intelligense, Salesforce data visualisation"}
          align={"left"}
          row={"left"}
          mainImg={BusinessIntelligense}
          subheading={
            "Business intelligence applied to data will save businesses money. These analyses enhance decisions and point out new trends or areas that the business needs to work on. A business, once it fully understands its data, can increase sales and services or pay attention to signals that can affect the business. We are also a Salesforce service provider and a Salesforce development company. Our services might be used for producing reports, documents, or presentations, whereby our presentation of data through understandable dashboards and interactive graphs helps us support better decision-making through real-time monitoring with visual analytics for our clients.​"
          }
        />

        <Line />
        <TextSet4
          heading={"Integration Salesforce"}
          // data={dataForSalesforceLightning}
          align={"left"}
          row={"right"}
          mainImg={SalesforceIntegration}
          subheading={
            "Our integration services allow you to have a single centralized user experience by connecting data and functionality from other CRM platforms. Our integration of Salesforce is offered in the form of automation of all end-to-end business processes, creating intensely designed user experiences that simplify the user's interaction with your platform. This means that your sales reps can have access to the data in one place where they would not have to toggle between different systems. Overall, this would lead to much more efficiency. Being a trusted Salesforce development company, we even provide Salesforce development services in order to take the CRM ability to the next level for you.​"
          }
        />
      </div>
    </div>
  );
}
