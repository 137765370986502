import React, { useState } from "react";
import { useEffect } from "react";
import './CareerPage.css'
import LandingPage from "../../comp/LandingPage/LandingPage";
import Line from "../../comp/Line/Line";
import CardContainer from "../../comp/CardContainer/CardContainer";
import { CareerBanner, Methodology, NewStartupIdea } from '../../assests/images';

export default function Career({ title }) {
    const [width, setWidth] = useState("");
    useEffect(function () {
        document.title = title;
    }, [title])

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWidth(width)
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    return (
        <div className="aboutpage__container">

            <LandingPage img={CareerBanner} subheading={'Working in Gravit Infosystems means new challenges and excellent opportunity to grow professionally and personally.'}
                heading={'BUILD YOUR CAREER HERE'} headingType={'text'} scroll={'openposition'} buttonText={'JOIN US'} />
            <div className="maininnercontainer" style={{
                display: 'flex', justifyContent: 'center', flexDirection: width > 600 ? 'row' : 'column', borderTop: '1px solid var(--themeGray)'
            }}>

                <div className="career__sub__maininnercontainer">
                    <div className="career__heading">
                        <h3 className="career__heading heading1">WE MAKE YOUR DREAMS AS REAL</h3>
                    </div>
                    <div className="career__subheading">
                        <p className="subheading">At Gravit Infosystems, we recognize that our success is driven by our talented workforce. Our approach is simple: we hire enthusiastic people who have a desire to excel, and we push them to fulfill their potential. We support your career goals and reward excellence. Our corporate culture fosters a fun work environment with lots of interaction to give you the opportunity to learn from others and dream big</p>
                    </div>
                </div>

            </div>
            <Line />
            <div id="openposition">
                <CardContainer />

            </div>
            <div className="CareerPage__form__Box">
                <div className="CareerPage__innerF__Box">
                    <h3 className="heading1">Apply For the Future Jobs at Gravit</h3>
                    <p className="subheading">Interested candidates please drop your C.V. and Cover Letter at
                        hr@gravitinfosystems.com</p>
                    <button className="careerPage__button">
                        <a href="mailto:hr@gravitinfosystems.com">Click Here</a>
                    </button>
                </div>

            </div>


        </div >
    )
}