import React, { useEffect } from "react";
import "./ItConsulting.css";
import LandingPage from "../../comp/LandingPage/LandingPage";
import CustomiseWeb from "../../comp/CustomiseWeb/CustomiseWeb";
import CardList from "../../comp/CardList/CardList";
import TextSet4 from "../../comp/TextSet4/TextSet4";
import TextSet6 from "../../comp/TextSet6/TextSet6";
import TextSet7 from "../../comp/TextSet7/TextSet7";
import Line from "../../comp/Line/Line";
import { Helmet } from "react-helmet";
import {
  IndustryExpertConsulting,
  ITConsultingLanding,
  ItSecurity,
  DataAnylatic,
  IctArchitecture,
  OpratingSystem,
  BossGivingInstryctions,
  EcommerceCamping,
  ApplicationDevelopment,
  Industry_Expertise_Counslting,
  Business_Consulting,
  Application_Consulting,
  Hardware_Consulting,
  ERP_Consulting,
  security_Consulting,
  Cost_Efficient,
  Full_Guidance,
  Security_Conscious,
  Statergy_Formulation,
  Assesment,
  Solution_Building,
  Ecommerce_B2B,
  IT_infrastructure,
  Data_Security,
  Data_Storage,
  Appropriate_SoftwareHardware,
  User_Training,
  Network_Solution,
  Disaster_Recovery,
  Interpreting_organisation,
  Architectures_technologies,
  Creating_migrating,
  Strategy_formulation,
  Latest_Applications,
  Assessment_evaluation,
  Solution_Recovery_ICON,
  Analytics_implementation,
  Upgrade_systems,
  Operations_support,
  Personalisation,
  Cost_Efficiency,
  Reliable_Security,


} from "../../assests/images";
export default function ItConsulting({ setActive, title }) {
  useEffect(
    function () {
      document.title = title;
      setActive("Service");
    },
    [setActive, title]
  );

  const consultingData = [
    {
      heading: "Industry Expertise",
      icon: Industry_Expertise_Counslting,
    },

    {
      heading: "E-Business Consulting",
      icon: Business_Consulting,
    },

    {
      heading: "Custom Application Development Consulting",
      icon: Application_Consulting,
    },

    {
      heading: "Systems and Hardware Consulting  ",
      icon: Hardware_Consulting,
    },

    {
      heading: "ERP, CRM and HCM consulting",
      icon: ERP_Consulting,
    },

    {
      heading: "IT security consulting",
      icon: security_Consulting,
    },
  ];

  const dataForCard = [
    { img: Cost_Efficient, heading: "Cost Efficient" },
    { img: Full_Guidance, heading: "Full Guidance" },
    { img: Security_Conscious, heading: "Security Conscious" },
    { img: Statergy_Formulation, heading: "Statergy Formulation" },
    { img: Assesment, heading: "Assesment" },
    { img: Solution_Building, heading: "Solution Building" },
    { img: Ecommerce_B2B, heading: "Ecommerce and B2B" },
  ];

  const dataForHardwareConsulting = [
    { img: IT_infrastructure, heading: "IT infrastructure development" },
    { img: Data_Security, heading: "Data Security Recommendation" },
    { img: Data_Storage, heading: "Data storage planning" },
    {
      img: Appropriate_SoftwareHardware,
      heading: "Appropriate software/hardware selection",
    },
    { img: User_Training, heading: "User training" },
    { img: Network_Solution, heading: "Network Solution" },
    { img: Disaster_Recovery, heading: "Disaster recovery and management" },
  ];
  const dataForE_BusinessConsulting = [
    {
      img: Interpreting_organisation,
      heading: "Interpreting your organisation’s",
    },
    {
      img: Architectures_technologies,
      heading: "Architectures and emerging technologies",
    },
    { img: Creating_migrating, heading: "Creating a vision for migrating" },
    { img: Appropriate_SoftwareHardware, heading: "Usability Engineering" },
    { img: Latest_Applications, heading: "Latest Internet Applications" },
    { img: Strategy_formulation, heading: "Strategy formulation" },
    { img: Assessment_evaluation, heading: "Assessment and evaluation" },
    {
      img: Solution_Recovery_ICON,
      heading: "Solution-building or integration",
    },
    {
      img: Analytics_implementation,
      heading: "Analytics design and implementation",
    },
    { img: Upgrade_systems, heading: "Upgrade existing systems" },
    {
      img: Operations_support,
      heading: "Operations, maintenance and support services",
    },
  ];
  const dataForCustomApplication = [
    { img: Personalisation, heading: "Personalisation" },
    { img: Cost_Efficiency, heading: "Cost efficiency" },
    { img: Reliable_Security, heading: "Reliable and security-conscious" },
  ];

  return (
    <div className="consulting__container">
      <Helmet>
        <title>Services - IT Consulting - Gravit Infosystems Pvt. Ltd.</title>
        <meta name="title" content=" IT Consulting  " />
        <meta
          name="description"
          content=" We offer IT consulting services like Systems & Hardware, ERP, CRM, HCM, E-Business, and Data Analytics to boost your business efficiency."
        />

        <meta
          name="keywords"
         t conten="IT consulting, IT consulting services, Top IT consulting company, IT services and it consulting    "
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <LandingPage
        img={ITConsultingLanding}
        subheading={
          "Unlock tailored IT solutions. Expert consulting for innovation, efficiency, propelling your business to sustained success in the dynamic digital landscape. Your path to excellence begins here. "
        }
        heading={
          "Customized IT Consulting Solutions for Business Excellence and Digital Transformation"
        }
        headingType={"text"}
        scroll={"contact"}
        redirect={"/contact"}
        buttonText={"GET STARTED"}
      />
      {/* <Line /> */}
      <div className="container">
        <div
          className="line"
          style={{
            width: "80%",
            height: "1px",
            borderBottom: "1px solid #fd9f1c",
          }}
        ></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <br />
            <br />
            <heading
              style={{
                color: "#fd9f1d",
                fontweight: "500",
                fontSize: "24px",
              }}
            >
              {"Our IT Consulting Process"}
            </heading>
          </div>
        </div>
      </div>
      <div className="container">
        <p
          style={{
            fontFamily: "Catamaran",
            fontSize: "14px",
            marginTop: "2rem",
            margin: "auto",
          }}
        >
          At Gravit Infosystems, we continue to innovate even after seven years.
          for different businesses is an essential component of our legacy.
          <br />
          We’re an incredible team of knowledgeable and skilled professionals,
          many of whom have over seven years of practical experience. What’s our
          strategy?
          <br />
          Quickly providing customized responses while guaranteeing measurable
          benefits each and every time. Trust Gravit Infosystems to deliver
          tailored solutions
          <br />
          that drive your business forward.​
        </p>
      </div>
      <div className="main">
        <div className="container1">
          <div class="box">
            <h3
              style={{ fontFamily: "Catamaran", textAlign: "center" }}
              class="box-title"
            >
              Analysis
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "catamaran",
                fontSize: "14px",
              }}
              class="box-content"
            >
              Our team of IT professionals just digs through all the software
              solutions you are using and how your team uses them in order to
              find problems with automation and workflow. We identify as one of
              the leading providers of IT consulting services..
            </p>
          </div>
          <div class="box">
            <h3
              style={{ fontFamily: "Catamaran", textAlign: "center" }}
              class="box-title"
            >
              Analysis
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "catamaran",
                fontSize: "14px",
              }}
              class="box-content"
            >
              Our team of IT professionals just digs through all the software
              solutions you are using and how your team uses them in order to
              find problems with automation and workflow. We identify as one of
              the leading providers of IT consulting services..
            </p>
          </div>
          <div class="box">
            <h3
              style={{ fontFamily: "Catamaran", textAlign: "center" }}
              class="box-title"
            >
              Strategy
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "catamaran",
                fontSize: "14px",
              }}
              class="box-content"
            >
              The consultants develop a plan and strategy to assist your
              business in utilizing the latest technologies and optimizing your
              software system. After that, key performance indicators (KPIs) for
              staff and software are created, verifying that you work with a top
              IT consulting company."​
            </p>
          </div>
          <div class="box">
            <h3
              style={{ fontFamily: "Catamaran", textAlign: "center" }}
              class="box-title"
            >
              Performance
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "catamaran",
                fontSize: "14px",
              }}
              class="box-content"
            >
              Effective IT consulting services require coordination between the
              client and the best IT consulting provider. Our experts will
              carefully analyze your workflows, monitoring the results to
              identify any problems, which will then be resolved by our software
              developers.“.
            </p>
          </div>
        </div>
      </div>

      <CustomiseWeb
        heading={
          " CUSTOMIZED WEB APPLICATION DEVELOPMENT SERVICES ENTAILING YOUR NEEDS."
        }
        data={consultingData}
      />
      <div className="itconsulting__wholecontent">
        <CardList
          dataForCard={dataForCard}
          /*    border={"1px solid var(--themeGray)"} */
          marginBottom={"2rem"}
        />
        <Line />
        <TextSet4
          heading={"Industry Expertise"}
          trim={200}
          mainImg={IndustryExpertConsulting}
          subheading={
            "We offer cutting-edge professional consulting services in IT, starting from IT administration up to application integration, from the front up to the back end-management of projects and the administration of databases, designing online and mobile apps, and general software development. We take the initiative to provide good-quality services designed to meet the needs of our clients. Our experts understand the industry profoundly and can provide exact consulting to devise accurate strategies and authentic designs that motivate the integration of flawless information technology systems for our clients. We can be of assistance to you in implementing new IT systems, optimizing the existing infrastructure, or developing new applications. We are one of the best IT consulting companies, providing you with the best results designed especially for you and your needs."
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        <TextSet4
          heading={"Systems and Hardware Consulting "}
          data={dataForHardwareConsulting}
          mainImg={OpratingSystem}
          align={"left"}
          row={"right"}
          subheading={
            "We provide comprehensive systems and hardware consulting services to help businesses improve their operations and meet their goals. Our team of experienced consultants analyzes, plans, advises, and assists in developing information systems that use technology strategically to meet business requirements. We understand the company's operational and functional requirements, and we provide IT consulting services that align with your goals to improve business operations and understand financial planning. As a top IT consulting company, we are dedicated to helping you achieve success through tailored solutions."
          }
        />
        <Line />
        <TextSet4
          heading={"ERP, CRM and HCM consulting"}
          trim={200}
          mainImg={BossGivingInstryctions}
          subheading={
            "We work with you to implement new ideas, increase production, and save costs so that you can offer better services. This is why we have customized enterprise resource planning (ERP), customer relations management (CRM), and human capital management (HCM) consulting services for your needs. Our experts will continually work with you to learn about your business operations, areas that will require improvements, and the recommended solutions that will bring you there. We take extreme pride in providing top-notch IT services and IT consulting, positioning us as a premier IT consulting firm dedicated to your success. ​"
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        {/* <TextSet4
          heading={"Our IT Consulting Process"}
          trim={200}
          mainImg={BossGivingInstryctions}
          subheading={
            "At Gravit Infosystems, we continue to innovate even after seven years. Developing a strategy for different businesses is an essential component of our legacy. We’re an incredible team of knowledgeable and skilled professionals, many of whom have over seven years of practical experience. What’s our strategy? Quickly providing customized responses while guaranteeing measurable benefits each and every time. Trust Gravit Infosystems to deliver tailored solutions that drive your business forward.​​"
          }
          align={"left"}
          row={"left"}
        />
        <Line /> */}
        <TextSet4
          heading={"E-Business Consulting"}
          data={dataForE_BusinessConsulting}
          mainImg={EcommerceCamping}
          align={"left"}
          row={"right"}
          subheading={
            "We set up an online business, which is often a complex process, and we are here to help solve the problems that will come your way. Our advisors help in the hassle-free setting up of your online business processes. We will strive to establish your e-business with efficiency, productivity, and profitability. We provide it consulting for an assurance that we arm you with the tools and support needed to be a success in today‘s digital environment. Your needs are fulfilled by our IT services and IT consulting offerings. Whatever your online plans, we are here to help. "
          }
        />
        <Line />
        <TextSet4
          heading={"Custom Application Development Consulting"}
          trim={200}
          data={dataForCustomApplication}
          mainImg={ApplicationDevelopment}
          subheading={
            "Our software consulting service is tailored  by our experts with more than a decade of experience in the industry. As one of the best It consulting firms, we have software development consultants  who take a strategic approach toward developing new optimizing business processes. Along with that, we also provide IT consulting to ensure that all your projects are aligned with your business goals."
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        <TextSet4
          heading={"ICT Architecture Consulting"}
          trim={200}
          mainImg={IctArchitecture}
          subheading={
            "Our software consulting service is tailored  by our experts with more than a decade of experience in the industry. As one of the best It consulting firms, we have software development consultants  who take a strategic approach toward developing new optimizing business processes. Along with that, we also provide IT consulting to ensure that all your projects are aligned with your business goals."
          }
          align={"left"}
          row={"right"}
        />
        <Line />
        <TextSet4
          heading={"IT security consulting"}
          trim={200}
          mainImg={ItSecurity}
          subheading={
            "Our software consulting service is tailored  by our experts with more than a decade of experience in the industry. As one of the best It consulting firms, we have software development consultants  who take a strategic approach toward developing new optimizing business processes. Along with that, we also provide IT consulting to ensure that all your projects are aligned with your business goals."
          }
          align={"left"}
          row={"left"}
        />
        <Line />
        <TextSet4
          heading={"Data analytics consulting"}
          trim={200}
          mainImg={DataAnylatic}
          subheading={
            "Our data analytics services monitor multiple stages for optimal consultation; the procedure begins with data collection, where we compile information from numerous sources, and is subsequently followed by data cleaning, which eliminates redundant information. As a top IT consulting company, we offer comprehensive services to ensure the highest data quality. Predictive models produce new data during the data modeling step to find trends in the original data, supported by our IT consulting expertise. The final phase involves visualizing data in the form of charts, graphs, etc. Decision-making skills are eventually improved with our IT consulting services."
          }
          align={"left"}
          row={"right"}
        />
      </div>
      <br />
    </div>
  );
}
